import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import GrantsChatbot from './page/GrantsChatbot';
import GrantsPage from './page/GrantsPage';
import LoginPage from './page/LoginPage';
import SignUpPage from './page/SignUpPage';
import MembersArea from './page/MembersArea';
import GrantsTable from './page/GrantsTable';
import Header from './page/Header';
import Footer from './page/Footer';
import ProtectedRoute from './ProtectedRoute';
import logo from './assets/logogrants.webp';
import arrow from './assets/arrow.svg';
import ConfirmEmailPage from './page/confirm_email';
import UserPage from './page/UserPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import './assets/css/style.css';
import { useAuth } from './AuthContext';
import SubscriptionPage from './page/SubscriptionPage'; // Ensure correct path
import Recomendation_grants from './page/Recomendation_grants'


const App = () => {
    const location = useLocation();
    const hideHeaderFooter = location.pathname === '/login' || location.pathname === '/signup';
  
    return (
        <div className="app-wrapper">
            {!hideHeaderFooter && <Header />}
            
            <main className="content-wrapper">
                <Routes>
                    {/*  <Route path="/" element={<ProtectedRoute><GrantsChatbot /></ProtectedRoute>} /> */}

                        {/*    <Route path="/" element={<ProtectedRoute><Recomendation_grants /></ProtectedRoute>} />*/}
                    
                    <Route path="/" element={  <ProtectedRoute> <Recomendation_grants /> </ProtectedRoute>} />
                    {/*    <Route path="/GrantsChatbot" element={<ProtectedRoute><GrantsChatbot /></ProtectedRoute>} />*/}
                    <Route path="/Filter_Grants" element={ <ProtectedRoute><GrantsTable /> </ProtectedRoute>   } /> 
                  

                    {/* <Route path="/Search_Grants" element={<ProtectedRoute><GrantsTable /></ProtectedRoute>} />*/}

                    <Route path="/confirm-email" element={<ConfirmEmailPage />} />
                    <Route path="/members" element={<ProtectedRoute><MembersArea /></ProtectedRoute>} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route path="/user" element={<UserPage />} />
                    <Route path="/subscribe" element={<SubscriptionPage />} />
                </Routes>
            </main>
        
            {!hideHeaderFooter && <Footer />}
        </div>
    );
};

export default App;
